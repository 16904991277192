import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { ThemeProvider, createGlobalStyle, css } from "styled-components";
// import { rem } from "polished";
import SEO from "../components/seo";

const theme = {
  font: "'Lato'",
  white: "ghostwhite",
  spacing: "10px",
};

const GlobalStyles = createGlobalStyle`
${({ theme }) => css`
  html,
  body {
    padding: 0;
    margin: 0;
    background: ${theme.white};
  }
  /* html,
  body,
  #___gatsby,
  #gatsby-focus-wrapper {
    height: 100%;
  } */
  body {
    font-family: ${theme.font};
    font-weight: 300;
    text-rendering: optimizeLegibility;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
  }
  canvas {
    display: block;
    cursor: grab;
    &:active {
      cursor: grabbing;
    }
  }

  /* .page {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
  } */
`}
`;
const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <ThemeProvider theme={theme}>
      <SEO title={data.site.siteMetadata.title} />
      {children}
      <GlobalStyles />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
