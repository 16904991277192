import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import * as THREE from "three";
import { OrbitControls } from "../lib/OrbitControls";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Layout from "../components/layout";
import SEO from "../components/seo";

const Help = styled.div`
  transition: all 0.4s;
  opacity: ${(props) => (props.showHelp ? 1 : 0)};
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  font-weight: bold;
`;

const IndexPage = () => {
  const [showHelp, setShowHelp] = useState(false);
  const ref = useRef();
  const raf = useRef();

  // must use useRef to preserve state between renders
  const dragging = useRef();

  let scene,
    camera,
    renderer,
    raycaster,
    controls,
    mouse,
    links = [];

  const nameText = `
DIXON CHENG
WEB DEVELOPER
`;

  const workText = `
MAZDA NEW ZEALAND
REALESTATE.CO.NZ
AJ HACKETT
COVID-19 MAP NZ
SAPIEN
ABO
BURGER KING
GENESIS
NISSAN AUSTRALIA
WAREHOUSE MONEY
TVNZ ANTARCTICA`;

  const contactText = `
EMAIL
LINKEDIN
GITHUB
`;

  useEffect(() => {
    init();

    // const geometry = new THREE.BoxGeometry(500, 500, 500);
    // const material = new THREE.MeshPhongMaterial({
    //   color: 0x156289,
    //   emissive: 0x072534,
    //   side: THREE.DoubleSide,
    //   // flatShading: true,
    // });
    // const cube = new THREE.Mesh(geometry, material);
    // scene.add(cube);

    // WIREFRAME
    const boxGeometry = new THREE.BoxGeometry(500, 500, 500);
    var geo = new THREE.EdgesGeometry(boxGeometry);
    var mat = new THREE.LineBasicMaterial({
      color: 0x000000,
      // linewidth: 2,
    });
    var wireframe = new THREE.LineSegments(geo, mat);
    scene.add(wireframe);

    // TEXT
    const loadText = () => {
      // var loader = new THREE.FontLoader();
      // loader.load("/helvetiker_regular.typeface.json", function (font) {
      //   // NAME
      //   let nameGeo = new THREE.TextGeometry("nameText ➞", {
      //     font: font,
      //     size: 26,
      //     height: 1,
      //   });

      //   nameGeo.computeBoundingBox();
      //   nameGeo.computeVertexNormals();
      //   nameGeo = new THREE.BufferGeometry().fromGeometry(nameGeo);

      //   const nameMesh = new THREE.Mesh(nameGeo, mat);

      //   nameMesh.position.x =
      //     -0.5 * (nameGeo.boundingBox.max.x - nameGeo.boundingBox.min.x);
      //   nameMesh.position.y =
      //     0.5 * (nameGeo.boundingBox.max.y - nameGeo.boundingBox.min.y);
      //   nameMesh.position.z = -249;

      //   scene.add(nameMesh);
      // });

      global.THREE = THREE;
      const createGeometry = require("three-bmfont-text");
      const loadFont = require("load-bmfont");
      const MSDFShader = require("three-bmfont-text/shaders/msdf");
      const textScale = 0.7;

      loadFont("/Lato-Black.fnt", function (err, font) {
        const nameGeo = createGeometry({
          font: font,
          text: nameText,
          width: 400 / textScale,
        });

        const contactGeo = createGeometry({
          font: font,
          text: contactText,
          align: "right",
          width: 400 / textScale,
        });

        const workGeo = createGeometry({
          font: font,
          text: workText,
          width: 400 / textScale,
        });

        var textureLoader = new THREE.TextureLoader();
        textureLoader.load("/Lato-Black.png", function (texture) {
          const material = new THREE.RawShaderMaterial(
            MSDFShader({
              map: texture,
              color: "black",
              side: THREE.DoubleSide,
              // transparent: true,
              negate: false,
            })
          );

          const nameMesh = new THREE.Mesh(nameGeo, material);
          nameMesh.position.x = -200;
          nameMesh.position.z = -249;
          // fix upside down text due to inside a cube
          nameMesh.rotation.y = Math.PI * 2;
          nameMesh.rotation.x = Math.PI;
          nameMesh.scale.x = textScale;
          nameMesh.scale.y = textScale;
          scene.add(nameMesh);

          const contactMesh = new THREE.Mesh(contactGeo, material);
          contactMesh.position.x = -249;
          contactMesh.position.y = 100;
          contactMesh.position.z = 200;
          // fix upside down text due to inside a cube
          contactMesh.rotation.y = -Math.PI / 2;
          contactMesh.rotation.x = Math.PI;
          contactMesh.scale.x = textScale;
          contactMesh.scale.y = textScale;
          scene.add(contactMesh);

          const workMesh = new THREE.Mesh(workGeo, material);
          workMesh.position.x = 249;
          workMesh.position.y = -170;
          workMesh.position.z = -200;
          // fix upside down text due to inside a cube
          workMesh.rotation.y = Math.PI / 2;
          workMesh.rotation.x = Math.PI;
          workMesh.scale.x = textScale;
          workMesh.scale.y = textScale;
          scene.add(workMesh);
        });
      });
    };
    loadText();

    // ADD ARROWS
    var loader = new GLTFLoader();
    loader.load(
      "/icon2/DC_Logo02.gltf",
      function (gltf) {
        const obj = gltf.scene || gltf.scenes[0];
        const mesh = obj.children[1];

        mesh.material = new THREE.MeshBasicMaterial({
          color: 0xf5f5f5,
          polygonOffset: true,
          polygonOffsetFactor: 1,
          polygonOffsetUnits: 1,
        });

        var wire = new THREE.LineSegments(
          new THREE.EdgesGeometry(mesh.geometry),
          new THREE.LineBasicMaterial({
            color: 0x000000,
            linewidth: 1,
          })
        );
        mesh.add(wire);
        obj.scale.set(300, 160, 160);

        const emaiArrow = obj.clone();
        emaiArrow.position.x = -250 + arrowThickness;
        emaiArrow.position.y = 177;
        emaiArrow.position.z = -205;
        emaiArrow.rotation.x = -Math.PI / 2;
        // emaiArrow.rotation.z = Math.PI / 2;
        scene.add(emaiArrow);

        const emailLink = makeLink("mailto:mail@dixoncheng.com");
        emailLink.rotation.y = Math.PI / 2;
        emailLink.position.x = -248;
        emailLink.position.y = 180;
        emailLink.position.z = -150;
        links.push(emailLink);
        scene.add(emailLink);

        const linkedInArrow = obj.clone();
        linkedInArrow.position.x = -247;
        linkedInArrow.position.y = 144;
        linkedInArrow.position.z = -205;
        linkedInArrow.rotation.x = -Math.PI / 2;
        // linkedInArrow.rotation.z = Math.PI / 2;
        scene.add(linkedInArrow);

        const linkedInLink = makeLink(
          "https://www.linkedin.com/in/dixon-cheng/"
        );
        linkedInLink.rotation.y = Math.PI / 2;
        linkedInLink.position.x = -248;
        linkedInLink.position.y = 145;
        linkedInLink.position.z = -150;
        links.push(linkedInLink);
        scene.add(linkedInLink);

        const githubArrow = obj.clone();
        githubArrow.position.x = -247;
        githubArrow.position.y = 109;
        githubArrow.position.z = -205;
        githubArrow.rotation.x = -Math.PI / 2;
        scene.add(githubArrow);

        const githubLink = makeLink("https://github.com/dixoncheng");
        githubLink.rotation.y = Math.PI / 2;
        githubLink.position.x = -248;
        githubLink.position.y = 110;
        githubLink.position.z = -150;
        links.push(githubLink);
        scene.add(githubLink);

        var mazdaArrow = obj.clone();
        mazdaArrow.position.x = 247;
        mazdaArrow.position.y = 180;
        mazdaArrow.position.z = 147;
        mazdaArrow.rotation.x = -THREE.Math.DEG2RAD * 1;
        // mazdaArrow.rotation.z = Math.PI / 2;
        scene.add(mazdaArrow);

        const mazdaLink = makeLink("https://www.mazda.co.nz/", 360);
        mazdaLink.position.x = 247;
        mazdaLink.position.y = 189;
        mazdaLink.position.z = -20;
        mazdaLink.rotation.y = Math.PI / 2;
        links.push(mazdaLink);
        scene.add(mazdaLink);

        const reArrow = obj.clone();
        reArrow.position.set(247, 145, 92);
        reArrow.rotation.set(-THREE.Math.DEG2RAD * 1, 0, 0);
        scene.add(reArrow);

        const reLink = makeLink("https://realestate.co.nz/", 310);
        reLink.position.set(247, 154, -50);
        reLink.rotation.set(0, Math.PI / 2, 0);
        links.push(reLink);
        scene.add(reLink);

        const c19Arrow = obj.clone();
        c19Arrow.position.x = 247;
        c19Arrow.position.y = 76;
        c19Arrow.position.z = 78;
        c19Arrow.rotation.x = -THREE.Math.DEG2RAD * 1;
        // c19Arrow.rotation.z = Math.PI / 2;
        // arrows.push(c19Arrow);
        scene.add(c19Arrow);

        const c19Link = makeLink("https://covid19map.nz/", 290);
        c19Link.position.set(247, 85, -55);
        c19Link.rotation.set(0, Math.PI / 2, 0);
        links.push(c19Link);
        scene.add(c19Link);

        const sapienArrow = obj.clone();
        sapienArrow.position.x = 247;
        sapienArrow.position.y = 41;
        sapienArrow.position.z = -76;
        sapienArrow.rotation.x = -THREE.Math.DEG2RAD * 1;
        // sapienArrow.rotation.z = Math.PI / 2;
        scene.add(sapienArrow);

        const sapienLink = makeLink("https://www.sapien.co.nz/", 140);
        sapienLink.position.set(247, 50, -135);
        sapienLink.rotation.set(0, Math.PI / 2, 0);
        links.push(sapienLink);
        scene.add(sapienLink);

        const aboArrow = obj.clone();
        aboArrow.position.x = 247;
        aboArrow.position.y = 6;
        aboArrow.position.z = -116;
        aboArrow.rotation.x = -THREE.Math.DEG2RAD * 1;
        // aboArrow.rotation.z = Math.PI / 2;
        // arrows.push(aboArrow);
        scene.add(aboArrow);

        const aboLink = makeLink("https://www.brandenburg.com.au/", 100);
        aboLink.position.set(247, 15, -155);
        aboLink.rotation.set(0, Math.PI / 2, 0);
        links.push(aboLink);
        scene.add(aboLink);

        const tvnzArrow = obj.clone();
        tvnzArrow.position.x = 247;
        tvnzArrow.position.y = -168;
        tvnzArrow.position.z = 90;
        tvnzArrow.rotation.x = -THREE.Math.DEG2RAD * 1;
        scene.add(tvnzArrow);

        const tvnzLink = makeLink("https://undertheice.tvnz.co.nz/", 310);
        // tvnzLink.position.set(247, 15, -155);
        tvnzLink.position.set(247, -154, -50);
        tvnzLink.rotation.set(0, Math.PI / 2, 0);
        links.push(tvnzLink);
        scene.add(tvnzLink);

        // scene.add(obj);
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    // console.log(exIcon);

    // ARROWS AND LINKS
    const arrowThickness = 5;
    const arrowRadius = 8;

    const makeArrow = (url) => {
      // const coneHeight = 10;
      // const cyliderHeight = 10;

      var tip = new THREE.Mesh(
        // new THREE.ConeGeometry(6, coneHeight, 4),
        new THREE.CylinderGeometry(arrowRadius, arrowRadius, arrowThickness, 3),
        new THREE.MeshBasicMaterial({
          color: 0xf5f5f5,
          polygonOffset: true,
          polygonOffsetFactor: 1,
          polygonOffsetUnits: 1,
        })
      );
      var tipWire = new THREE.LineSegments(
        new THREE.EdgesGeometry(tip.geometry),
        // new THREE.ConeBufferGeometry(12, 12, 5),
        new THREE.LineBasicMaterial({
          color: 0x000000,
        })
      );
      tip.add(tipWire);
      tip.rotation.x = -Math.PI / 2;
      tip.userData = { url };
      tipWire.userData = { url };

      // var body = new THREE.Mesh(
      //   new THREE.CylinderGeometry(3, 3, cyliderHeight, 4),
      //   new THREE.MeshBasicMaterial({
      //     color: "white",
      //     polygonOffset: true,
      //     polygonOffsetFactor: 1,
      //     polygonOffsetUnits: 1,
      //   })
      // );
      // var bodyWire = new THREE.LineSegments(
      //   new THREE.EdgesGeometry(body.geometry),
      //   new THREE.LineBasicMaterial({ color: "black" })
      // );
      // body.add(bodyWire);
      // body.position.y = -7.5;
      // body.rotation.y = Math.PI / 4;

      // var group = new THREE.Group();
      // group.add(tip);
      // group.add(body);

      // group.userData = { url };

      // return group;

      return tip;
    };

    const makeLink = (url, width = 190) => {
      const plane = new THREE.Mesh(
        new THREE.PlaneGeometry(width, 30),
        new THREE.MeshBasicMaterial({
          side: THREE.DoubleSide,
          // color: "red",
          transparent: true,
          opacity: 0,
        })
      );
      plane.userData = { url };
      return plane;
    };

    // var dir = new THREE.Vector3(1, 2, 0);

    // //normalize the direction vector (convert to vector of length 1)
    // dir.normalize();

    // var origin = new THREE.Vector3(0, 0, 0);
    // var length = 1;
    // var hex = "red";

    // var arrowHelper = new THREE.ArrowHelper(dir, origin, length, hex);
    // scene.add(arrowHelper);

    // BACK WALL
    var loader = new THREE.TextureLoader();
    loader.load(
      "/drum.png",
      function (texture) {
        var backWallMat = new THREE.MeshBasicMaterial({
          map: texture,
          side: THREE.DoubleSide,
        });

        const backWallGeo = new THREE.PlaneGeometry(300, 200);
        const plane = new THREE.Mesh(backWallGeo, backWallMat);
        plane.rotation.z = (-Math.PI * 3) / 4;
        plane.position.z = 249;
        // plane.userData = { url: "https://google.com" };
        scene.add(plane);
      },

      // onProgress callback currently not supported
      undefined,

      // onError callback
      function (err) {
        console.error("An error happened.");
      }
    );

    animate();

    // CLEAN UP
    return () => {
      window.removeEventListener("resize", resize);
      cancelAnimationFrame(raf.current);
    };
  }, [ref]);

  const init = () => {
    // SCENE / CAMERA
    scene = new THREE.Scene();
    camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.01,
      10000
    );
    camera.position.z = 150;

    // if (window.innerHeight > window.innerWidth) {
    // }

    // CONTROLS
    controls = new OrbitControls(camera, ref.current);
    controls.enableZoom = false;
    controls.enablePan = false;
    controls.rotateSpeed = -1;
    controls.enableDamping = true;
    controls.minDistance = 0;
    controls.maxDistance = 249;
    controls.rotateLeft(-45 * THREE.Math.DEG2RAD);

    // RENDEDER
    renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    renderer.setClearColor(0xf5f5f5, 0);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);
    ref.current.appendChild(renderer.domElement);

    // LIGHTS
    // const light1 = new THREE.AmbientLight(0xf5f5f5, 0.5),
    //   light2 = new THREE.PointLight(0xf5f5f5, 1);

    // scene.add(light1);
    // scene.add(light2);

    // const lights = [];
    // lights[0] = new THREE.AmbientLight(0xf5f5f5, 1, 0);
    // lights[1] = new THREE.PointLight(0xf5f5f5, 1, 0);
    // lights[2] = new THREE.PointLight(0xf5f5f5, 0.5, 0);

    // lights[0].position.set(0, 200, 0);
    // lights[1].position.set(100, 200, 100);
    // lights[2].position.set(-100, -200, -100);

    // // scene.add(lights[0]);
    // // scene.add(lights[1]);
    // scene.add(lights[2]);

    // MOUSE
    mouse = new THREE.Vector2();
    // const INTERSECTED;

    raycaster = new THREE.Raycaster();

    // ATTACH EVENTS
    window.addEventListener("resize", resize, false);
    ref.current.addEventListener("pointerdown", onDocumentPointerDown, false);
    ref.current.addEventListener("mouseup", onDocumentPointerUp, false);
    ref.current.addEventListener("touchend", onDocumentPointerUp, false);
    ref.current.addEventListener("pointermove", onDocumentPointerMove, false);

    window.addEventListener("wheel", onWheel, false);
  };

  // RENDER LOOP
  const animate = function () {
    raf.current = requestAnimationFrame(animate);
    // cube.rotation.x += 0.01;
    // cube.rotation.y += 0.01;
    raycaster.setFromCamera(mouse, camera);
    controls.update();
    renderer.render(scene, camera);
  };

  // RESIZE LISTENER
  const resize = () => {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  };

  // EVENT LISTENERS
  const onDocumentPointerMove = (event) => {
    dragging.current = true;
    mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
    mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
    raycaster.setFromCamera(mouse, camera);
    const intersects = raycaster.intersectObjects(links, true);

    if (intersects.length > 0) {
      document.getElementsByTagName("canvas")[0].style.cursor = "pointer";
    } else {
      document.getElementsByTagName("canvas")[0].style.cursor = null;
    }
  };
  const onDocumentPointerDown = () => (dragging.current = false);
  const onDocumentPointerUp = (event) => {
    if (dragging.current) {
      return;
    }
    if (event.type === "touchend") {
      mouse.x =
        (event?.changedTouches[0]?.pageX / renderer.domElement.clientWidth) *
          2 -
        1;
      mouse.y =
        -(event?.changedTouches[0]?.pageY / renderer.domElement.clientHeight) *
          2 +
        1;
    } else {
      mouse.x = (event.clientX / renderer.domElement.clientWidth) * 2 - 1;
      mouse.y = -(event.clientY / renderer.domElement.clientHeight) * 2 + 1;
    }
    raycaster.setFromCamera(mouse, camera);
    // const intersects = raycaster.intersectObjects(arrows, true);
    const intersects = raycaster.intersectObjects(scene.children, true);

    // console.log(intersects);

    if (intersects.length > 0) {
      // intersects[0].object;

      // console.log(intersects[0].object);
      // if (intersects[0].object?.parent?.userData?.url) {
      //   window.open(intersects[0].object.parent.userData.url);
      // }

      for (let i in intersects) {
        if (intersects[i].object?.userData?.url) {
          window.open(intersects[i].object.userData.url);
        }
      }
    }
  };

  let timeout;
  const onWheel = () => {
    setShowHelp(true);
    timeout = setTimeout(() => {
      clearTimeout(timeout);
      setShowHelp(false);
    }, 1500);
  };

  return (
    <Layout>
      <SEO
        title="Dixon Cheng - Freelance Frontend Developer, Auckland, New Zealand"
        meta={[
          {
            property: "og:image",
            content: "https://dixoncheng.com/FBPreview.png",
          },
          {
            property: "og:image:width",
            content: "1200",
          },
          {
            property: "og:image:height",
            content: "630",
          },
          {
            property: "og:image:type",
            content: "image/png",
          },
        ]}
      />
      <div ref={ref} />
      <Help showHelp={showHelp}>Please drag</Help>
    </Layout>
  );
};

export default IndexPage;
